@use 'global-variables' as *;
@use 'mixins' as *;
@import 'form-validation';
@import '_source-sans-pro';
@import 'roboto-condensed';
@import '../../../../../shared/styles/responsive-breakpoints';
@import 'material-icons';
// Rich text editor
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import 'quill-theming';

@font-face {
    font-family: 'Euro Plate';
    src:
        url('/assets/fonts/EuroPlate.woff') format('woff'),
        url('/assets/fonts/EuroPlate.ttf') format('truetype');
}

* {
    box-sizing: border-box;
}

html {
    background-image: url(/assets/images/background-autoixpert.jpg);
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    color: #5c5c5c;
    font-size: 15px;
    --primary-color: #15a9e8;
    --primary-color-rgb: 21, 169, 232;
    --primary-color-lighter: #0adfff;
    --primary-color-darker: #{darken(#15a9e8, 5%)};
    --primary-color-dark: #206d8d;
    --primary-gradient-halfway: #0ecbf7;
    --font-light-grey: #c1c1c1;
    --font-medium-grey: #a1a1a1;
    --font-medium-grey-darker: #{darken(#a1a1a1, 3%)};
    --font-dark-grey: #5c5c5c;
    --dark-blue: #525d76;
    // Dark Mode
    --dark-grey: #464d5d;
    --dark-grey-rgb: 70, 77, 93;
    --dark-primary-color: #515c6d;
    --button-dark-mode: #4d5466;
    --button-hover-dark-mode: #586074;
    --divider-on-dark-grey-card: #51596c;

    --selection-color: #def4fd;
    --light-element-background-color: #f4f6fa;
    --light-element-hover-color: #e6eaf2;
    --light-element-helper-icon-color: #b0c0d4;
    --short-payment-color: #bc52cc;
    --success-color: #00dc3a;
    --error-color: #da0000;
    --error-color-darker: #{darken(#da0000, 3%)};
    --warning-color: #ffae00;
    --positive-value-font-color: #29cf55;
    --negative-value-font-color: #f24949;
    --info-note-button-font-color: #12a1e2;
    --info-note-button-background-color: rgb(var(--primary-color-rgb), 5%);
    --info-note-button-background-color-hover: rgb(var(--primary-color-rgb), 10%);

    &.qapterixpert {
        --primary-color: #f27e20;
        --primary-color-rgb: 242, 126, 32;
        --primary-color-lighter: #ffa514;
        --primary-color-darker: #{darken(#f27e20, 5%)};
        --primary-color-dark: #995c2b;
        --primary-gradient-halfway: #fa9619;
        //--dark-grey: #444854; // use same color
        --dark-primary-color: #485369; // TODO
        --selection-color: #fee2cd;
        --light-element-background-color: #fff5eb;
        --light-element-hover-color: #f2e5d8;
        --light-element-helper-icon-color: #bfb5ab;
        --info-note-button-font-color: var(--primary-color);
        --info-note-button-background-color: rgb(var(--primary-color-rgb), 13%);
        --info-note-button-background-color-hover: #{darken(#ffe6bc, 5%)};
        background-image: url(/assets/images/background-qapter.jpg);

        img[src$='-blue.svg'],
        img[src$='-blue-gradient.png'],
        img[src$='-blue.jpg'] {
            // Such as claimant-blue.svg or rocket-blue-gradient.png
            filter: hue-rotate(-165deg); // Move from our blue to roughly Audatex' orange.
        }
    }
}

body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    height: 100%;
    font-size: 15px;
}
.gradient_stop_primary-color {
    stop-color: var(--primary-color);
}
.gradient_stop_primary-color {
    stop-color: var(--primary-color-lighter);
}

// Don't use general styling on Chrome, only on browser not supporting ::-webkit-scrollbar
@supports not selector(::-webkit-scrollbar) {
    html {
        scrollbar-width: thin;
        scrollbar-color: rgba(#000, 0.5) rgba(#c1c1c1, 0.3);
    }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px; // Width of vertical scrollbars.
    height: 7px; // Height of horizontal scrollbars.
}

*::-webkit-scrollbar-track {
    background: rgba(#c1c1c1, 0.3);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(#000, 0.5);
    border-radius: 99px;
}

autoixpert-app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

h1 {
    font-size: 18pt;
    font-weight: 200;
}

h2,
h3,
h4 {
    text-transform: uppercase;
    font-weight: 600;
}

h2 {
    font-size: 1.2em;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 25px;
}

h3 {
    font-size: 1em;
}

h4 {
    font-size: 10pt;
}

a {
    text-decoration: none;
    color: var(--primary-color);

    &.secondary {
        color: var(--font-medium-grey);
    }
}

/**
The rich text editor Quill adds `type="button"` to the buttons. We do not want to style their buttons, though.
 */
button:not(.mat-icon-button):not(.mat-button):not(.mat-button-base):not([type='button']) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    transition: all 0.3s;

    &:not(.glass) {
        background-color: var(--primary-color);
        border-radius: 2px;
        box-shadow: 0 1px 2px rgba(#000, 0.25);
        padding: 6px 12px;
        margin-left: 5px;
        margin-right: 5px;
        color: #fff;
        border: 2px solid transparent;
        &:hover {
            filter: var(--primary-color-darker);
            box-shadow:
                0 4px 8px rgba(#000, 0.2),
                0 5px 10px rgba(#000, 0.1);
        }

        .button-overlay {
            background-color: var(--primary-color);
        }
    }

    &.secondary {
        background-color: transparent;
        padding-top: 6px;
        padding-bottom: 6px;
        color: var(--primary-color);
        border: 2px solid var(--primary-color);

        &:hover {
            border-color: var(--primary-color-darker);
            color: var(--primary-color-darker);
        }

        &.filled {
            background-color: white;
        }

        .button-overlay {
            background-color: white;
        }

        mat-icon {
            color: inherit;
        }
    }

    // Only use with default (primary) and .secondary button
    &.delete {
        // styles for secondary delete button
        border-color: var(--error-color);
        color: var(--error-color);

        &:hover {
            background-color: var(--error-color);
            border-color: var(--error-color); // Important to overwrite .secondary:hover's rule
            color: white;
        }

        &:not(.secondary) {
            // styles for primary delete button
            background-color: var(--error-color);
            color: white;

            &:hover {
                background-color: var(--error-color-darker);
            }
        }
    }

    // Don't use the disabled property since it disallows
    // placing a tooltip on a disabled button.
    // https://github.com/angular/material2/issues/2576
    &.disabled,
    &[disabled] {
        background-color: #c9c9c9;
        border-color: #c9c9c9;
        cursor: not-allowed;
        color: #ffffff;
        transition: none;

        &:hover {
            // Overwrite the button:hover value with the button regular shadow value.
            box-shadow: 0 1px 2px rgba(#000, 0.25);
            border-color: #c9c9c9;
            color: white;
        }
    }

    &.interface-opener {
        background-color: #ffffff;
        color: #5c5c5c;

        &:hover {
            background-color: #f6f6f6;
        }
    }

    &.flat {
        box-shadow: none;
        background-color: transparent;
        border-color: transparent;
        color: var(--font-medium-grey);

        &:not(.disabled):not([disabled]):hover {
            box-shadow:
                0 4px 8px rgba(#000, 0.2),
                0 5px 10px rgba(#000, 0.1);
            color: #808080;
        }

        &.disabled:hover,
        &[disabled]:hover {
            box-shadow: none;
            border-color: transparent;
            color: var(--font-medium-grey);
        }

        &.light {
            color: white;

            &:hover {
                color: white;
            }
        }

        &.with-shadow {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        }

        .button-overlay {
            background-color: white;
        }

        mat-icon {
            color: inherit;
        }
    }

    &.show-more-button:not(.disabled) {
        background: rgba(#000, 0.5);
        color: white;
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 1;
        }
    }

    .pending-icon {
        height: 16px;
        margin-right: 8px;
    }

    .button-icon {
        margin-right: 8px;
        color: white;
    }

    mat-icon {
        color: white;
        width: 21px;
        height: 21px;
        font-size: 21px;
        margin-top: -2px; // Compensate vertical padding
        margin-bottom: -2px;
    }
}

// After performing the button's action, overlay it with a box that usually contains a checkmark icon, a loading message or a confirmation message.
.button-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;

    .button-action-done-icon {
        color: #8bc34a;
        font-size: 21px;
        width: 21px;
        height: 21px;
    }
}

button.glass {
    $backgroundColor: rgba(var(--primary-color-rgb), 0.12);
    display: flex;
    align-items: center; // Center e.g. the pending indicator on the button.
    background-color: $backgroundColor;
    color: var(--primary-color);
    padding: 8px 12px;
    border-radius: 3px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    box-shadow: none;
    transition: background 0.2s ease;

    &:hover {
        background-color: rgba(var(--primary-color-rgb), 0.2);
    }
    .pending-icon {
        width: 15px;
        height: 15px;
    }

    .button-overlay {
        background-image: linear-gradient($backgroundColor, $backgroundColor), linear-gradient(white, white);
    }
}

div.dashed-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 2px dashed var(--font-medium-grey);
    border-radius: 4px;
    color: var(--font-medium-grey);
    margin-bottom: 20px;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:not(:hover) {
        opacity: 0.7;
    }

    &.disabled {
        opacity: 0.7;
    }
}

input,
select,
textarea {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #c1c1c1;
    padding: 0 5px;
    width: 100%;
    color: #5c5c5c;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;

    &[type='checkbox'] {
        width: auto;
    }

    &[type='range'] {
        border-bottom: none;
    }

    &[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &[type='file'] {
        display: none;
    }

    &.number-input {
        text-align: right;
    }
}

input,
textarea {
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    user-select: initial !important;
}

/*
* Split-Button
*/
.split-button {
    display: flex;
    align-items: stretch;
    background-color: var(--light-element-background-color);
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    overflow: hidden;

    &.flat {
        box-shadow: none;
        background-color: transparent;
        border-color: transparent;
        color: var(--font-medium-grey);

        &.with-shadow {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        }
    }

    &.attract-focus {
        color: var(--primary-color-darker);
        .split-button-left,
        .split-button-right {
            &:hover {
                color: var(--primary-color-darker);
            }
        }
    }

    // Shared styles for left and right button part
    .split-button-left,
    .split-button-right {
        display: flex;
        align-items: center;
        transition:
            background 0.2s ease,
            color 0.2s ease;
        &:hover {
            background-color: darken(#f4f6fa, 5%);
            color: var(--primary-color);
        }
    }
    .split-button-left {
        font-size: 0.9rem;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
        @media (min-width: $lg) {
            padding-left: 13px;
            padding-right: 13px;
        }
    }

    .split-button-right::before {
        background-color: #e1e1e1;
        content: '';
        margin-right: 6px;
        $marginTop: 7px;
        height: calc(100% - 2 * #{$marginTop});
        width: 1px;
    }

    .split-button-right {
        padding-left: 0;
        padding-right: 6px;
        mat-icon {
            color: inherit;
        }
    }
}

// Have the color input of the accent color appear inline instead of the top left corner of the screen.
input[type='color'] {
    position: absolute;
    padding: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
    top: 0;
    border-bottom: none;
    width: unset; // Using width/height 1px or 0 results in the picker overlay not being shown on Safari.
    height: unset;
}

textarea {
    box-sizing: initial;
}

/**
Starting in Angular v16, Angular Material sets the default icon color to "inherit". Since material styles are inlined,
they take precedence over these style, so we make these styles more specific by using both the element and the class name.
 */
mat-icon.mat-icon {
    color: var(--font-medium-grey);
    user-select: none;
    transform: rotate(0.03deg); // Trigger anti-aliasing to make small icons look sharp instead of pixelated.

    &.small-icon {
        font-size: 15px;
        width: 1rem;
        height: 1rem;
    }

    &.medium-icon {
        font-size: 19px;
        width: 19px;
        height: 19px;
    }

    &.moderate-icon {
        font-size: 21px;
        width: 21px;
        height: 21px;
    }

    &.regular-size-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
    }

    &.large-icon {
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
    }

    &.toolbar-icon {
        margin: 0 5px;
        opacity: 0.5;
        transition: all 0.2s ease;

        &:not(.disabled):hover {
            cursor: pointer;
            opacity: 1;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.warning {
        color: var(--warning-color);
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.icon-hidden-until-hovered {
    transition: opacity 0.2s ease;
    @media (hover: hover) {
        .icon-hidden-until-hovered-parent:not(:hover) & {
            opacity: 0;
        }
    }
}

.colored-icon-on-light-background-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: rgba(var(--primary-color-rgb), 0.12);
    border-radius: 50%;

    mat-icon {
        font-size: 19px;
        width: 19px;
        height: 19px;
        color: var(--primary-color);
    }
}

.toolbar-spacer-with-line {
    display: inline-block;
    width: 1px;
    height: 18px;
    margin: 3px 10px;
    background-color: #f1f1f1;

    &:last-child {
        // In case this spacer is the last element in a toolbar -> nothing to separate
        display: none;
    }
}

input[type='date'] {
    //cursor: pointer;
    //background-image: url(../../../assets/images/icons/calendar_24.png);
    //background-position: center right;
    //background-repeat: no-repeat;
}

$matFormFieldLightModeColor: rgba(#fff, 0.8);
// This offset makes sure that the placeholder does not overlap any element right above the input container.
mat-form-field {
    &.without-hint-spacing {
        margin-bottom: -1.25em;
    }

    &.without-title-spacing {
        .mat-form-field-infix {
            border-top: 0;
        }
    }

    &.search-field {
        .mat-input-placeholder {
            color: var(--font-medium-grey);
        }

        [matPrefix] {
            position: relative;
            top: 3px;
            margin-right: 5px;
            font-size: 15px;
        }
    }

    &.no-underline {
        .mat-form-field-underline {
            display: none;
        }
    }

    &.no-ripple {
        .mat-form-field-underline {
            .mat-form-field-ripple {
                display: none;
            }
        }
    }

    &.light-mode {
        .mat-form-field-infix {
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    color: $matFormFieldLightModeColor;
                }
            }

            .mat-select-value-text,
            input,
            .mat-form-field-suffix {
                color: white;
            }

            .mat-select-arrow {
                color: $matFormFieldLightModeColor;
            }
        }

        .mat-form-field-suffix {
            [matSuffix] {
                color: white;
            }
        }

        .mat-form-field-wrapper {
            // Add .div to increase CSS precedence
            div.mat-form-field-underline {
                background-color: $matFormFieldLightModeColor;
            }
        }
    }

    &.white-mode {
        .mat-form-field-infix {
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    color: #f1f1f1;
                }

                .mat-form-field-underline {
                    background-color: #f1f1f1;
                }

                .mat-select-value-text,
                input {
                    color: white;
                }

                &.mat-focused {
                    .mat-form-field-label {
                        color: #b3e9ff;
                    }

                    .mat-form-field-underline {
                        background-color: #b3e9ff;
                    }
                }
            }
        }
    }

    &.ghost-until-hover {
        &:not(.mat-focused):not(:hover) {
            .mat-form-field-wrapper {
                .mat-form-field-underline {
                    background-color: transparent;
                }
            }

            //[matsuffix] {
            //    opacity: 0;
            //}
        }

        .ghost-button-parent:hover & {
            .mat-form-field-wrapper {
                .mat-form-field-underline {
                    background-color: $matFormFieldLightModeColor;
                }
            }
        }

        .mat-form-field-wrapper {
            .mat-form-field-underline {
                transition: background 0.3s ease;
            }
        }

        //[matsuffix] {
        //    opacity: 1;
        //    transition: opacity .3s ease;
        //}
    }

    /**
     * This is different from the cdkTextareaAutosize directive as in that it grows automatically to the available height, while the cdk directive only grows based on the text in it.
     */
    &.autogrow-vertically {
        height: 100%;

        .mat-form-field-wrapper,
        .mat-form-field-flex,
        .mat-form-field-infix {
            height: 100%;
        }

        textarea {
            height: 100%;
        }
    }
}

// Increase specificity over html.qapterixpert (...)
div.mat-form-field-hint-wrapper mat-hint.mat-hint,
mat-icon {
    &.warning {
        color: var(--warning-color);
    }

    &.error {
        color: #fa0000;
    }
}

.multiple-inputs-row {
    display: flex;
    justify-content: space-between;

    & > autocomplete-with-memory,
    mat-form-field,
    date-input {
        flex: 1 1 100%;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.multiple-inputs-grid {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    grid-gap: 5px;
}

.axle-load-input {
    max-width: 49.5%;
}

.multiple-inputs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 10px;
}

.mat-tooltip {
    // Allow line breaks in Tooltips
    white-space: pre-line;
    // Allow tooltips to use more screen estate. Default is 250px.
    max-width: 350px !important;
}

.link {
    color: var(--primary-color);
    cursor: pointer;

    &.light {
        color: white;
        text-decoration: underline;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.emoji {
    font-family: 'Segoe UI Emoji', emoji;
}

//*****************************************************************************
//  Card Toolbar
//****************************************************************************/
.card-toolbar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
}

.toolbar-button-with-text {
    display: inline-flex;
    align-items: center;
    opacity: 0.8;
    cursor: pointer;
    color: var(--font-medium-grey);
    transition: all 0.2s ease;

    &:hover {
        opacity: 1;
        color: var(--primary-color);
    }

    mat-icon {
        margin: 0 5px;
        color: inherit;
    }

    & + .toolbar-button-with-text {
        // spacing between toolbar buttons
        margin-left: 10px;
    }
}

.heading-and-toolbar-container {
    position: relative;

    .heading-toolbar {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.horizontal-toolbar {
    display: flex;
    align-items: center;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Card Toolbar
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Icon with Rim
//****************************************************************************/
.icon-on-background-with-rim {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(var(--primary-color-rgb), 0.2);
    background: rgba(var(--primary-color-rgb), 0.12);
    padding: 10px;
    margin-right: 20px;

    mat-icon {
        color: var(--primary-color);
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Icon with Rim
/////////////////////////////////////////////////////////////////////////////*/

.ax-header-select {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active {
        .ax-header-select-label {
            border-bottom-color: var(--primary-color);
            font-weight: 600;
        }
    }
}

.ax-header-select-label {
    padding-top: 3px; // Compensate padding bottom
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
    transition: border 0.2s ease;
}

.ax-header-select-arrow {
    border-top: 5px solid #5c5c5c;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    width: 0;
    height: 0;
    margin-left: 10px;
    display: inline-block;
}

/**
Style the container of time inputs. This cannot be done in the TimeInputComponent's CSS because
the material components' elements will have to contain the TimeInputComponent's id which they do not.
**/
.time-input-container .mat-input-infix {
    padding-bottom: 0.15em;
}

.semibold {
    font-weight: 600;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.monospaced {
    font-family: monospace;
}

mat-icon.highlighted-icon-blue {
    color: var(--primary-color);
}

.help-indicator {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid currentColor;
    border-radius: 50%;
    margin-left: 0.5em;
    color: #c1c1c1;
    font-size: 0.8em;
    width: 1.3em;
    aspect-ratio: 1;
    cursor: default;
    transition: all 0.3s ease;
    min-height: 0; // Prevent bug: Height in flex container is too large when align-items=center

    /**
    In case the help indicator is used within a mat-menu, a regular line-height ensures the correct display.
     */
    line-height: normal;

    &:hover {
        background-color: #f1f1f1;
    }
    &.dark {
        &:hover {
            background-color: var(--dark-grey);
        }
    }
    button & {
        color: white;
        &:hover {
            color: #a1a1a1;
        }
    }
}

/**
 The following styles have been moved from report-list.scss
 */
#sort-reports-by-container {
    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-infix {
            border-top: 0;
        }

        .mat-form-field-underline {
            bottom: 2px;
            // Hide the underline unless field is clicked
            height: 0;
        }
    }
}

/**
The following style has been moved from the variable-car-informatino.scss.

The reason is that Angular appends an attribute selector in the shape of [ng-content_c7]
to every part of a selector specified in a component's css. However, it does not append that attribute
to the HTML elements *inside* their material components.
I. e. #mileage-container and #mileage-unit-select get that extra attribute from the renderer
and their styles in this sheet are correctly applied.
.mat-select-trigger does not get that ng attribute in the HTML, but the last selector part
becomes .mat-select-trigger[ng-content_c7] in the rendered CSS rules. Therefore the HTML is not matched by this rule.
 */
#mileage-container #mileage-unit-select .mat-select-trigger {
    min-width: 70px;
}

.input-field-icon {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
        transition: opacity 0.2s;
    }
}

img {
    user-select: none;
    -moz-user-select: none;

    &.medium-icon {
        // Should be the same as the rule for mat-icon
        width: 19px;
        height: 19px;
    }
}

// This class was used in Material Design version 1. Remove as soon as the v2 implementation has caught up.
mat-form-field {
    &.mat-block {
        width: 100%;
    }
}

.mat-block {
    display: block;
    width: 100%;
}

.input-container {
    &.input-with-icon {
        position: relative;
        //Achtung, die folgenden Styles basieren auf dem Layout für die Kopfleiste. Muss bei Gelegenheit aus allgemeiner
        //Def. entfernt und mit allgemein gültigen ersetzt werden.
        img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 4px;
            left: 0;
        }

        input {
            padding-left: 20px;
        }
    }
}

//*****************************************************************************
//  Checkbox
//****************************************************************************/
.checkbox-group-container {
    display: flex;
    justify-content: center;

    .checkbox-group {
        width: -moz-fit-content;
        width: fit-content;
    }
}

.checkbox-group {
    // If there's only one checkbox in the group
    &.parent-of-an-only-child {
        text-align: center;
        padding-left: 0;
    }

    text-align: left;
    padding-left: 4%;
    margin: 10px 0;

    &.centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0;

        mat-checkbox {
            display: block;
        }
    }
}

// Replace horizontal line with white question mark in indeterminate checkbox state
.mat-checkbox {
    .mat-checkbox-mixedmark {
        background: none;
        width: unset;
        height: unset;

        &::after {
            content: '?';
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            font-family: 'Source Sans Pro', system-ui, sans-serif;
            color: #808080;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Checkbox
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Slide Toggle
//****************************************************************************/
.slide-toggle-small {
    transform: scale(0.8);
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Slide Toggle
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Mat Select (styled)
//****************************************************************************/
mat-select.styled {
    display: flex;
    height: 28px;
    padding: 0 8px 0 13px;
    align-items: center;
    gap: 5px;

    border-radius: 3px;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

    .mat-select-value-text {
        color: var(--font-medium-grey-a-1, #a1a1a1);
        font-family: 'Source Sans Pro';
        font-size: 13.33px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 5px;
    }

    .mat-select-arrow {
        opacity: 0.4;
        border-left-width: 4px;
        border-top-width: 4px;
        border-right-width: 4px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Mat Select (styled)
/////////////////////////////////////////////////////////////////////////////*/

[matSuffix].align-center {
    display: flex;
    align-items: center;
}

.pending-icon {
    margin-right: 8px;
    opacity: 1;

    // Fixed dimensions keep the loading indicator from "jumping" if the image needs yet to be loaded from the server.
    width: 24px;
    height: 24px;

    &.toolbar-icon {
        margin: 0 5px;
    }
}

//*****************************************************************************
//  Zip & Place
//****************************************************************************/
.place-group {
    display: flex;

    .zipCode {
        width: 4em;
        margin-right: 12px;
    }

    .place {
        flex-grow: 1;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Zip & Place
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Date & Time
//****************************************************************************/
.date-and-time-group {
    display: flex;
    justify-content: space-between;

    date-input {
        margin-right: 12px;
        flex-grow: 1;
    }

    time-input {
        flex-grow: 1;
        // Required for Firefox 86. If this width is omitted, the field would be too narrow and the user couldn't read the time. Not an issue in Chrome.
        min-width: 80px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Date & Time
/////////////////////////////////////////////////////////////////////////////*/

.name-group {
    display: flex;

    .salutation {
        width: 5em;
        margin-right: 12px;
    }

    .first-name {
        margin-right: 12px;
        flex-grow: 1;
    }

    .last-name {
        flex-grow: 1;
    }
}

//*****************************************************************************
//  Input Toolbar
//****************************************************************************/
.input-with-external-icon-container {
    position: relative;
}

.input-external-icon {
    position: absolute;
    top: 15px;
    right: -35px;
    cursor: pointer;
}

.show-icon-on-card-hover {
    @media (hover: hover) {
        transition:
            color 0.2s ease,
            opacity 0.2s ease;
        opacity: 0;
        .card:hover & {
            opacity: 0.4;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.input-external-more-vert-icon {
    position: absolute;
    top: 15px;
    right: -35px;
    cursor: pointer;
}

.input-toolbar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 18px;
    right: 3px;
}

// class for mat-icon and svg elements
.input-toolbar-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    color: var(--font-medium-grey);
    vertical-align: bottom;
    cursor: pointer;
    opacity: 0.4;
    outline: none;
    transition:
        color 0.2s ease,
        opacity 0.2s ease;

    path {
        fill: var(--font-medium-grey);
        transition: fill 0.2s ease;
    }

    &.white-toolbar-icon {
        color: #fff;

        &:hover {
            opacity: 1;
        }
    }

    &:not(.white-toolbar-icon):hover {
        color: var(--primary-color);
        opacity: 1;

        path {
            fill: var(--primary-color);
        }
    }

    &.no-repositioning {
        top: 0;
    }

    &.inside-multiline-form-field {
        position: absolute;
        right: 0;
        bottom: 32px;
        top: unset;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Input Toolbar
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Selection Bar
//****************************************************************************/
.selection-bar {
    display: flex;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(#000, 0.12);
}

.selection-bar-label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 4px;
}

.selection-bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px 8px;
    color: #d1d1d1;
    font-size: 0.9rem;
    font-weight: 600;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;

    // spacing between label + icon
    gap: 6px;

    &:not(:last-child) {
        border-right: 1px solid #f1f1f1;
    }

    &:hover:not(.disabled):not(.not-allowed):not(.selected) {
        color: var(--font-medium-grey);
    }

    &.selected {
        color: var(--primary-color);
        background-color: var(--light-element-background-color);

        &.selection-color-red {
            color: var(--error-color);
        }

        &.selection-color-orange {
            color: var(--warning-color);
        }

        &.selection-color-grey {
            color: var(--font-medium-grey);
        }
    }

    &.not-allowed,
    &.disabled {
        cursor: not-allowed;
    }

    .selection-bar.increased-padding & {
        padding: 8px;
    }

    mat-icon {
        color: inherit;
    }
    .selection-bar-item-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Selection Bar
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Photo Thumbnails
//****************************************************************************/
.avatar-image-container {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 50%;
    }
}

.thumbnail {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Photo Thumbnails
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Page Layout
//****************************************************************************/
.three-columns-layout-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;

    &.justify-content-center {
        justify-content: center;
    }

    .one-third-data-column {
        width: 31%;
        margin-top: 30px;
        margin-right: 15px;
        margin-left: 15px;
        @media (max-width: $lg) {
            width: 32.5%;
        }
    }

    .two-thirds-data-column {
        width: 65.5%;
        margin-top: 30px;

        .one-third-column {
            width: 47.5%;
            @media (max-width: $lg) {
                width: 48.9%;
            }
        }

        @media (max-width: $lg) {
            width: 67.5%;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Page Layout
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Cards
//****************************************************************************/
.card {
    box-shadow:
        0 2px 8px rgba(0, 0, 0, 0.2),
        0 15px 30px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    &.dark-card {
        color: white;
        //background-color: #515c6d;
        background-color: var(--dark-grey);

        h2 {
            color: white;
        }

        label {
            //color: white;
        }
    }

    &.on-dark-background {
        border-radius: 5px;
        background: rgb(55 61 73 / 0.92);
        backdrop-filter: blur(10px);
        box-shadow:
            0 1px 1px 0 rgba(0, 0, 0, 0.07),
            0 2px 2px 0 rgba(0, 0, 0, 0.07),
            0 4px 4px 0 rgba(0, 0, 0, 0.07),
            0 8px 8px 0 rgba(0, 0, 0, 0.07),
            0 16px 16px 0 rgba(0, 0, 0, 0.07);
    }
}

.card-with-padding {
    --card-padding-x: 50px;

    padding: 35px 50px 40px 50px;
    @media (max-width: $lg) {
        padding: 35px 40px 40px;
        --card-padding-x: 40px;
    }

    &.error-card {
        background-color: red;
    }
}

.card-submenu {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.elevated-card {
    box-shadow:
        0 10px 30px rgba(#000, 0.08),
        0 5px 10px rgba(#000, 0.12);
    transition: all 0.2s ease;

    &:hover {
        box-shadow:
            0 20px 50px rgba(#000, 0.13),
            0 7px 12px rgba(#000, 0.2);
    }
}

/**
 * Usually flat, but elevated on hover.
 */
.elevated-card-on-hover {
    transition: all 0.3s ease;

    &:hover {
        box-shadow:
            0 9px 15px rgba(#000, 0.13),
            0 5px 9px rgba(#000, 0.1);
    }
}

.with-primary-gradient {
    background-image: linear-gradient(45deg, var(--primary-color), var(--primary-color-lighter));
    color: white;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Cards
/////////////////////////////////////////////////////////////////////////////*/

.record {
    background-color: #ffffff;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background-color: #f4f6fa;
    }

    &.active,
    &.selected {
        background-color: var(--selection-color);
    }
}

//*****************************************************************************
//  Floating Action Button (FAB)
//****************************************************************************/
.floating-action-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 1; // Don't be covered by other simple positioned elements unless z-index > 1
    border-radius: 50%;
    background-color: var(--primary-color);
    box-shadow: 0 1px 3px rgba(#000000, 0.35);
    color: #ffffff;
    text-align: center;
    font-size: 17pt;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:
        box-shadow 0.3s ease,
        background 0.3s ease;

    &:not(.disabled):hover {
        box-shadow: 0 4px 14px rgba(black, 0.5);
        background-color: var(--primary-color-darker);
        cursor: pointer;
        transition:
            box-shadow 0.3s ease,
            background 0.3s ease;
    }

    .text {
        transform: rotate(0deg);
        transition: transform 0.35s;
        // If this were not present, the text would "jump" after the transformation due to anti-aliasing
        will-change: transform;
    }

    &:hover .text {
        transform: rotate(180deg);
        transition: transform 0.35s;
    }

    &.disabled {
        background-color: #c9c9c9;
        cursor: not-allowed;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Floating Action Button (FAB)
/////////////////////////////////////////////////////////////////////////////*/

/******************************************************************************
/* List with multiple options
/*****************************************************************************/
.options-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    @media (max-width: $lg) {
        overflow-x: auto; // Allow scrollbars on mobile
        overflow-y: hidden; // Prevent one-pixel overflow
    }

    &.grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
        gap: 15px;
        justify-content: initial; // Compensate the justify-content: center of .options-row
    }
}

.option-container {
    cursor: pointer;
    user-select: none;

    &.unselectable {
        cursor: default;
    }
}

.option-image,
.option-icon {
    max-height: 48px;
    max-width: 48px;
    filter: grayscale(100%);
    opacity: 0.4;
    transition: all 0.2s;

    .option-container:not(.unselectable):hover & {
        filter: grayscale(0);
        color: var(--primary-color);
        opacity: 1;
        transform: translateY(-4px);
        transition: all 0.2s;
    }

    .option-container.active &,
    .option-container.selected & {
        filter: grayscale(0);
        color: var(--primary-color);
        opacity: 1;
        transition: all 0.2s;
    }

    .option-container.unselectable & {
        cursor: default;
        transition: opacity 0.2s;
    }

    .option-container.unavailable & {
        opacity: 0.2;
    }
}

.option-title {
    border-bottom: 1px solid transparent;
    color: var(--font-medium-grey);
    font-size: 0.9em;
    transition: all 0.2s;

    .option-container:not(.unselectable):hover & {
        opacity: 1;
        color: unset;
    }

    .option-container.active &,
    .option-container.selected & {
        opacity: 1;
        border-bottom-color: var(--primary-color-darker);
        color: unset;
    }
}

.option-container-with-icon-and-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: unset; // Allow container to take up full size.
    height: 100%;
    padding: 20px 15px;

    .option-image {
        $optionImageHeight: 32px;
        width: $optionImageHeight;
        height: $optionImageHeight;
        font-size: $optionImageHeight;
    }

    .option-title {
        font-size: 15px;
        margin-top: 5px;
        font-weight: 600;
        margin-bottom: 2px;
        border-bottom: none;
    }
}

/******************************************************************************
/* END List with multiple options
/*****************************************************************************/

//*****************************************************************************
//  Angular Dialog
//****************************************************************************/
mat-dialog-container.mat-dialog-container {
    position: relative;
    overflow: auto;
    max-height: 90vh;

    mat-dialog-actions {
        margin-bottom: 0;
    }

    // Nesting required for higher specificity compared to the Qapter-iXpert styles.
    div.dialog-with-blue-gradient-header & {
        background-image: linear-gradient(45deg, var(--primary-color), var(--primary-color-lighter));
        background-size: 100% 60%;
        background-repeat: no-repeat;

        // Nesting increases CSS selector specificity. Must be higher than .mat-dialog-title setting the heading to dark grey.
        [matDialogTitle].mat-dialog-title,
        [matDialogClose] {
            color: white;
        }
    }
}

.dialog-without-padding {
    mat-dialog-container {
        padding: 0;
    }
}

.dialog-without-padding-bottom {
    mat-dialog-container {
        padding-bottom: 0;
    }
}

.dialog-with-extra-padding {
    mat-dialog-container {
        padding: 40px 60px;
    }
}

mat-dialog-actions {
    gap: 10px 0; // The buttons shall be 10px apart vertically on mobile. On Desktop, buttons already have horizontal margin.
    justify-content: center;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Angular Dialog
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Dialog
//****************************************************************************/
.dialog-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding-top: $head-runner-height; // Compensate for the head runner of the app
    background: rgba(#000, 0.35);
}

.dialog-container {
    background: white;
    box-shadow:
        0 11px 15px -7px rgba(0, 0, 0, 0.2),
        0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    max-width: 95vw; // Limit width on mobile devices
    max-height: $dialog-container-max-height;
    padding: 40px;
    position: relative;
    overflow-y: auto;

    // This is the go-to-approach for longer dialogs such as the document type dialog.
    &.dialog-grid {
        // Ensure that the content between the heading and the buttons scales and is scrollable.
        display: grid;
        grid-template-rows: auto 1fr auto;

        // Padding will be applied to the scrollable area so that the scroll bar is on the very left.
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: $lg) {
        max-width: 800px;
    }

    &.contains-scrollable-area {
        display: flex;
        flex-direction: column;
    }

    &.multi-column {
        display: flex;
        padding: 0;
        background: unset;
        box-shadow: unset;
        overflow: visible;
    }
}

.dialog-intro {
    text-align: center;
    margin-bottom: 20px;
}

.dialog-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }

    &.light {
        color: white;
    }
}

.dialog-scrollable-area {
    max-height: calc(100% - 80px);
    overflow: auto;

    .dialog-grid & {
        // For the CSS grid layout, no max-height is required. It creates too much white space.
        max-height: none;

        // The parent container's padding is removed and applied here so that the scroll bar is on the very left.
        padding-right: 40px;
        padding-left: 40px;
    }
}

.dialog-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 10px;

    &.sticky {
        position: sticky;
        bottom: 0;
        background: white;
        margin-bottom: 0;
        padding-top: 10px;
        padding-bottom: 20px;
        border-top: 1px solid #f1f1f1;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Dialog
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Process bar
//****************************************************************************/
.module-router-outlet-container {
    width: 95%;
    max-width: 1700px;
    margin: 0 auto;
    padding-bottom: 40px;
    @media (max-width: $lg) {
        width: 97%;
    }
}

.module-process-bar {
    width: 95%;
    max-width: 1700px;
    height: 40px;
    margin: 33px auto 0;
    display: flex;
    @media (max-width: $lg) {
        width: 97%;
        height: 50px;
        margin-top: 25px;
        overflow-x: auto;
    }
}

.process-bar-elements-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 10px;
    @media (min-width: $lg) {
        padding: 0;
        justify-content: space-around;
    }
}

.process-bar-element-container {
    text-align: center;
    flex-grow: 1;
    padding-left: 20px;
    padding-right: 20px;
}

.process-bar-element {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    text-decoration: unset;
    color: unset;
    margin: 0 auto;
    padding-bottom: 2px;
    font-size: 0.9rem;
    white-space: nowrap;
    @media (min-width: $lg) {
        font-size: 15px;
    }

    &.active {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 3px;
            width: calc(
                100% + 15px
            ); // Widen the bar to stretch into the padding area. Esepcially important on mobile for short menu labels like "Fotos"
            border-radius: 6px 6px 0 0;
            background-color: var(--primary-color);
        }
    }
}

.process-bar-element-label {
    position: relative;

    report-progress-indicator-icon {
        position: absolute;
        top: 50%;
        right: -22px;
        transform: translateY(-50%);
    }
}

.process-bar-icon-container {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;

    &.active {
        border-bottom-color: var(--primary-color);
    }

    &.important {
        border-bottom-color: red;
    }

    .process-bar-element {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.process-bar-icon {
    width: 20px;
    height: 20px;
}

.process-bar-option {
    padding-top: 8px;
    width: 40px;
    text-align: center;
    cursor: pointer;

    &:last-child {
        margin-right: 10px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Process bar
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Generic Font-Styling
//****************************************************************************/

.label {
    font-size: 10pt;
    color: var(--font-medium-grey);

    &.warning {
        color: var(--warning-color);
    }

    &.error {
        color: #fa0000;
    }

    &.light {
        color: var(--font-light-grey);
        transition: color 0.2s ease;
        &:hover {
            color: var(--font-medium-grey);
        }
    }
}

.italic {
    font-style: italic;
}

.emphasized-number {
    font-size: 2em;
    color: var(--primary-color);
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Generic Font-Styling
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Tabs
//****************************************************************************/
.tabs-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-heading {
    margin-left: 20px;
    margin-right: 20px;
    opacity: 0.5;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
    transition:
        opacity 0.2s ease,
        border 0.2s ease;

    &.selected {
        opacity: 1;
        border-bottom-color: var(--primary-color);
    }

    &:hover {
        opacity: 1;
    }

    &:only-child {
        border-bottom: none;
        cursor: inherit;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Tabs
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Autocomplete / Select Add-Ons
//****************************************************************************/
mat-option .autocomplete-option-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px; // Padding top and bottom prevent cutting off of long letters like g and y.
    padding-bottom: 2px;

    .mat-icon {
        margin-right: 0;
        transition:
            opacity 0.2s ease,
            color 0.2s ease;
        @media (hover: hover) {
            opacity: 0;
        }

        &.active {
            color: #ffd600;
            opacity: 1;
        }
    }

    &:hover {
        .mat-icon {
            &:not(.active) {
                opacity: 0.4;
            }

            &:not(.readonly):not(.disabled):hover {
                opacity: 1;
                color: var(--primary-color);
            }
        }
    }
}

mat-option .autocomplete-option-wrapper-with-user-avatar {
    justify-content: flex-start;

    user-avatar {
        margin-right: 10px;
    }

    > span,
    > div {
        flex-grow: 1;
    }
}

.autocomplete-option-label {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option.option-with-label,
.mat-autocomplete-panel .mat-option.option-with-label,
.mat-menu-panel .mat-option.option-with-label {
    line-height: 1;
    height: 3.5em;

    .label {
        margin-top: 4px;
    }
}

.mat-option.compact-option,
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option.compact-option {
    line-height: 1.5;
    height: 2em;
}

.mat-option.mat-option-with-icon {
    .mat-option-text {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        span {
            flex: 0 1 auto;
            @include truncate-ellipsis();
        }

        mat-icon {
            margin: 0;
            flex: 0 0 auto;
        }
    }
}

.mat-select-panel.mat-select-width-400 {
    min-width: min(450px, 80vw) !important;
}

// Even denser than the compact option
.mat-option.dense-option {
    height: 1.8em;
}

mat-optgroup .mat-optgroup-label {
    font-weight: bold;
    line-height: 1.5;
    height: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    color: #5c5c5c;
    margin-top: 5px;
}

.mat-menu-item,
[mat-menu-item] {
    &.menu-item-with-icon {
        display: flex;
        align-items: center;

        mat-icon,
        svg:not(.mat-menu-submenu-icon), // .mat-menu-submenu-icon = class on the submenu arrow for nested menus.
        img {
            max-width: 24px;
            max-height: 24px;
            margin-right: 16px;
            color: var(--font-medium-grey);
        }

        &.alert {
            color: var(--error-color);

            mat-icon {
                color: inherit;
            }

            &[disabled] {
                opacity: 0.5;
            }
        }

        .option-text {
            flex-grow: 1;
        }

        .toolbar-icon {
            margin-right: 0;
            margin-left: 16px;
        }
    }

    &.option-with-label {
        line-height: 1;
        height: 3.5em;

        .label {
            margin-top: 4px;
        }
    }

    &.menu-item-with-toggle,
    &.menu-item-with-check-mark {
        display: flex;
        align-items: center;
        // Aligns right side of toggles vertically
        justify-content: space-between;

        .menu-item-label {
            flex: 1 1 auto;
        }
    }

    // Don't use the disabled property since it disallows
    // placing a tooltip on a disabled button.
    // https://github.com/angular/material2/issues/2576
    &.disabled {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.38);
        transition: none;
    }

    &[disabled] {
        // Angular Material's default theme makes a disabled option have a default cursor.
        cursor: not-allowed !important;
        // Allow matTooltip to show.
        pointer-events: all !important;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Autocomplete / Select Add-Ons
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Notifications
//****************************************************************************/
.simple-notification-wrapper {
    min-width: 375px !important;
    max-width: min(600px, 90vw);
    max-height: calc(100vh - 20px);

    // Allow scrolling through long error messages.
    overflow-y: auto;
    // Error toasts slide in from the right. Prevent this x-axis movement from causing a horizontal scrollbar.
    overflow-x: hidden;

    width: unset !important;
    z-index: 1001 !important; // Notifications shall be in front of Angular's panels (z-index: 1000)
}

simple-notification .simple-notification {
    border-left: 3px solid transparent;
    border-radius: 3px;
    box-shadow:
        0 2px 4px rgba(#000, 0.2),
        0 5px 10px rgba(#000, 0.1);

    &.success {
        background: rgba(#263840, 0.9);
        border-left-color: #8bc34a;

        .icon svg {
            fill: #8bc34a;
        }

        .reason-label {
            color: #8bc34a;
        }
    }

    &.info {
        background: rgba(#263840, 0.9);
        border-left-color: #15a9e8;

        .icon svg {
            fill: #15a9e8;
        }

        .reason-label {
            color: #15a9e8;
        }
    }

    &.warn {
        background: rgba(#263840, 0.9);
        border-left-color: #ffdb5b;

        .icon svg {
            fill: #ffdb5b;
        }

        .reason-label {
            color: #ffdb5b;
        }
    }

    &.error {
        background: rgba(#263840, 0.9);
        border-left-color: #f44336;

        .icon svg {
            fill: #f44336;
        }

        .reason-label {
            color: #f44336;
        }
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .reason:first-of-type {
        margin-top: 15px;
    }

    .reason-label {
        font-weight: bold;
    }

    .reason-title {
        font-weight: bold;
    }

    .sn-title,
    .sn-content {
        color: #fff !important; // Without the important keyword, we'd have to declare this rule for all notification levels (success, warn, error, info)
    }

    .sn-title-without-padding {
        line-height: 30px;
        font-size: 20px;
    }

    .sn-content {
        font-size: 14px !important; // Without the important keyword, we'd have to declare this rule for all notification levels (success, warn, error, info)
        white-space: pre-line; // Break lines if there are \n characters
    }

    .sn-partner-logo {
        max-width: 60px;
        max-height: 60px;
        margin-right: 20px;
    }

    ol {
        // Ensure ordered lists are aligned with the rest of the text in the notification.
        padding-inline-start: 14px;
    }

    //*****************************************************************************
    //  Custom Notification
    //****************************************************************************/
    &.simple-notification-custom-content {
        & > div > .sn-content {
            white-space: initial;
        }
    }

    .simple-notification-custom-content-wrapper {
        display: flex;
        align-items: center;
    }

    .simple-notification-custom-icon {
        width: 40px;
        max-height: 40px;
        margin-right: 20px;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Notification
    /////////////////////////////////////////////////////////////////////////////*/
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Notifications
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Notes
//****************************************************************************/
.info-note-padding-container {
    padding: 15px 20px;
}

$infoNotePadding: 20px;
.info-note {
    display: flex;
    align-items: flex-start;
    position: relative; // Position the close icon relatively to this element.
    padding: $infoNotePadding;
    background-color: #f4f6fa;
    border-radius: 3px;

    &.warning-note {
        background-color: #fff0d6;

        mat-icon:not(.toolbar-icon):not(.no-color) {
            color: var(--warning-color);
        }
    }

    &.error-note {
        background-color: #f2dada;

        mat-icon:not(.toolbar-icon):not(.no-color) {
            color: var(--error-color);
        }
    }

    &.success-note {
        background-color: #f4faf6;

        mat-icon:not(.toolbar-icon):not(.no-color) {
            color: #4cc966;
        }
    }

    &.small-note {
        font-size: 0.9rem;
    }

    & > mat-icon:not(.toolbar-icon):not(.info-note-close-icon) {
        margin-right: 15px;
        color: var(--primary-color);
        flex-shrink: 0;

        &.no-color {
            color: var(--font-medium-grey);
        }
    }

    &.one-liner-note {
        align-items: center;
        justify-content: center;
        padding: 5px 12px;
        border-radius: 99px;
        font-size: 0.9rem;

        mat-icon:not(.toolbar-icon):not(.info-note-close-icon) {
            margin-right: 8px;
        }
    }

    &.centered {
        display: inline-flex;
    }

    .info-note-text {
        flex-grow: 1;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    &-preserve-close-icon-space {
        // use this class to prevent text from overlapping the close icon (upper right)
        margin-right: 24px;
    }

    & + .info-note {
        margin-top: 15px; // Spacing between info notes
    }
}

.info-note-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
}

// An image to adorn the info note.
.info-note-image {
    margin-right: $infoNotePadding;
    align-self: center;
}

.info-note-buttons-row {
    margin-top: 10px;
}

@mixin infoNoteButton {
    display: inline-block;
    border-radius: 6px;
    padding: 5px 10px;
    border: 2px solid transparent;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
    transition: all 0.3s;
}

// Apply this class to divs instead of buttons to circumvent our default button styles.
.info-note-button-primary {
    @include infoNoteButton;
    color: var(--primary-color);
    background-color: var(--info-note-button-background-color);
    &:not(:last-of-type) {
        margin-right: 5px;
    }
    mat-icon {
        color: var(--info-note-button-font-color);
    }
    &:hover {
        background-color: var(--info-note-button-background-color-hover);
        box-shadow: none;
    }
}

// Apply this class to divs instead of buttons to circumvent our default button styles.
.info-note-button-secondary {
    @include infoNoteButton;
    color: var(--font-medium-grey);
    &:hover {
        background-color: var(--info-note-button-background-color-hover);
        box-shadow: none;
    }
}

.important-note {
    color: #ff0000;
    margin-left: 5px;
    margin-right: 5px;
    outline: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
        transition: opacity 0.2s;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Notes
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Dark Card Action Button
//****************************************************************************/
.dark-card-action-button {
    padding: 6px 12px;
    border-radius: 3px;
    background: var(--button-dark-mode);
    color: rgba(white, 0.8);
    font-size: 0.9rem;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        filter: brightness(1.1);
    }
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Dark Card Action Button
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Alignment
//****************************************************************************/
input.text-align-end {
    text-align: right;
}

.text-align-end {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

input.text-align-start,
.text-align-start {
    text-align: left;
}

.align-self-flex-start {
    align-self: flex-start;
}

.flex-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-flex {
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-grow {
    flex-grow: 1;
}

.flex-align-center {
    align-items: center;
}

.flex-direction-column {
    flex-direction: column;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Alignment
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Text Color
//****************************************************************************/
.color-blue {
    color: var(--primary-color);

    &.mat-menu-item {
        color: var(--primary-color);
    }
    &.mat-icon {
        color: var(--primary-color);
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Text Color
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Text Overflow
//****************************************************************************/
.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Text Overflow
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Padding & Margin
//****************************************************************************/
.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-margin-top {
    margin-top: 0;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Padding & Margin
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Car Data Component
//****************************************************************************/
.base-model-option .mat-option-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Car Data Component
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Diminished Value Calculator
//****************************************************************************/
.mat-autocomplete-panel.damage-intensity-autocomplete {
    min-width: 250px;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Diminished Value Calculator
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Overlay Backdrops
//****************************************************************************/
.panel-transparent-backdrop {
    background: none;
}

.report-dialog-transparent-backdrop {
    background: none;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Overlay Backdrops
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Drag & Drop
//****************************************************************************/
.drop-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // Must be displayed above the navigation and the head bar.
    z-index: 999;
    background: white;
    border: 3px dashed #c1c1c1;

    &.fixed {
        position: fixed; // Used for full screen dropzones
    }

    &.disabled {
        border-color: var(--error-color);
        animation: border-pulse-red 0.8s infinite alternate;
        cursor: not-allowed;
    }

    &.file-over {
        border: 2px solid var(--primary-color);
        animation: border-pulse-blue 0.8s infinite alternate;
    }
}

@keyframes border-pulse-blue {
    0% {
        border-color: var(--primary-color);
    }
    100% {
        border-color: rgba(var(--primary-color-rgb), 0.1);
    }
}

@keyframes border-pulse-red {
    0% {
        border-color: var(--error-color);
    }
    100% {
        border-color: rgba(var(--error-color), 0.1);
    }
}

mat-icon[cdkDragHandle] {
    cursor: move;
}

mat-icon.drag-handle {
    @media (hover: none) {
        // On touch devices the drag handles are always visible (not only shown on hover
        // opposed to desktop). To make them appear less prominent, we decrease the opacity
        opacity: 0.5;
    }
}

// Class will be activated when the document is dragged to be rearranged.
.cdk-drag-preview {
    border-radius: 4px;
    box-shadow:
        0 1px 2px -3px rgba(0, 0, 0, 0.2),
        0 3px 5px 1px rgba(0, 0, 0, 0.1),
        0 3px 5px 2px rgba(0, 0, 0, 0.05);

    // If no background was set, the background would be transparent.
    background: #ffffff;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    background: #f1f1f1;
    border: dotted 3px #e1e1e1;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

    // Hide all contents
    & * {
        opacity: 0;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Drag & Drop
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  CDK Virtual Scroll Viewport
//****************************************************************************/

// Fix issues with item width as content-wrapper is not always as wide as the viewport.
.cdk-virtual-scroll-vertical-only {
    overflow-x: hidden;

    .cdk-virtual-scroll-content-wrapper {
        width: 100%;
        max-width: 100%;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END CDK Virtual Scroll Viewport
/////////////////////////////////////////////////////////////////////////////*/

.helpcenter-link-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:hover) {
        color: var(--font-medium-grey);
    }

    .video-link-play-icon {
        display: block;
        font-size: 15px;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        color: inherit;
    }
}

.helpcenter-link {
    color: inherit;
    font-size: 0.8rem;
    transition: all 0.2s ease;

    &:hover {
        text-decoration: underline;
    }
}

.video-tutorial-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 15px;
    .video-tutorial-title,
    .video-tutorial-icon {
        color: rgba(#fff, 0.7);
    }
    mat-icon {
        color: inherit;
        margin-right: 10px;
    }
    &:hover {
        .video-tutorial-title,
        .video-tutorial-icon {
            color: white;
        }
    }
    &.elevated {
        color: white;
        order: -1;
        margin-top: 0;
        margin-bottom: 20px;
        padding: 25px;
        background: linear-gradient(45deg, var(--primary-color), var(--primary-color-lighter));
        .label {
            color: white;
        }
    }
}

//*****************************************************************************
//  File Upload
//****************************************************************************/
.uploaded-file-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 2px 0;
    background-color: rgba(var(--primary-color-rgb), 0.1);
}

.uploaded-file-attachment-icon {
    margin-right: 15px;
}

.file-name {
    flex: 1;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END File Upload
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Reporting
//****************************************************************************/
.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
    color: #e1e1e1;
    fill: var(--font-medium-grey);
    font-weight: 600;
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;

    tspan {
        font-family: 'Source Sans Pro', sans-serif;
    }
}

// Align tooltip values on the right side
.apexcharts-tooltip-text {
    flex-grow: 1;
}

// Align tooltip values on the right side
.apexcharts-tooltip-y-group {
    display: flex;
    justify-content: space-between;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Reporting
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Videos
//****************************************************************************/
.video-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border-radius: 6px;
    background: white;
    cursor: pointer;
}

.video-thumbnail-container {
    position: relative; // For ::after content
}

.video-thumbnail {
    display: block;
}

.video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translate3d(-50%, -55%, 0); // 55% compensates the drop shadow within the image
}

.video-title {
    font-weight: bold;
    margin-top: 5px;
}

.video-duration {
    color: var(--font-medium-grey);
    font-size: 0.9rem;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Videos
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Selection Box
//****************************************************************************/
.selection-box {
    position: relative;
    background-color: #f4f6fa;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;

    &.selected {
        border-color: var(--primary-color);

        .option-image {
            color: var(--primary-color);
        }
    }
}

.selection-box-active-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 3px;
    font-weight: 600;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.2s ease;

    .selection-box.selected &,
        // If the direct parent is selected, increase opacity as well, no matter the direct parent's class.
    .selected > & {
        opacity: 1;
    }

    mat-icon {
        color: white;
        width: 13px;
        height: 13px;
        font-size: 13px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Selection Box
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Large Selection Box
//****************************************************************************/
/**
 * Use for multiple selection options that are more visually appealing than a simple dropdown list.
 *
 * If required in a row, you must configure the parent container to be a flex box or CSS grid.
 */
.large-selection-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    min-height: 90px;
    margin-top: 15px;
    font-weight: 600;
    background-color: #f4f6fa;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:has(.large-selection-box-option-icon) {
        // If an icon is present, there may be labels and sublabels on
        // some options, while not on others. This setting makes sure
        // that the labels are vertically aligned across boxes.
        justify-content: flex-start;
        padding: 10px;
    }

    .large-selection-box-option-icon {
        transition: color 0.2s ease;
    }

    &.selected {
        border-color: var(--primary-color);
        .large-selection-box-option-icon {
            color: var(--primary-color);
        }
    }
}

.large-selection-box-active-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 3px;
    font-weight: 600;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.2s ease;

    .large-selection-box.selected &,
        // If the direct parent is selected, increase opacity as well, no matter the direct parent's class.
    .selected > & {
        opacity: 1;
    }

    mat-icon {
        color: white;
        width: 13px;
        height: 13px;
        font-size: 13px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Large Selection Box
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Table of Contents
//****************************************************************************/
.table-of-contents-column {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 30px;
    min-width: 250px;
    max-width: 300px;
    flex-shrink: 1;
    max-height: calc(
        100vh - #{$head-runner-height} - 30px - 30px
    ); // 100vh - head runner height - margin-top of screen - padding-bottom of screen
    margin-right: 40px;
}

.table-of-contents-loader {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.table-of-contents-container {
    padding-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.table-of-contents-heading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--primary-color);
}

.table-of-contents-heading {
    color: white;
    margin: 0;
    max-width: calc(100% - 70px);
}

.table-of-contents-submenu-icon {
    position: absolute;
    right: 15px;
}

.table-of-contents-list {
    margin-top: 5px;
}

.table-of-contents-item-container {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
}

.table-of-contents-list-item {
    display: flex;
    align-items: center;
    padding: 3px 10px 3px 12px;
    color: #fff;
    opacity: 0.8;
    transition: all 0.2s ease;

    &.heading {
        padding-left: 2px;
        text-transform: uppercase;
        opacity: 1;
    }

    &.clickable {
        cursor: pointer;
    }

    &:hover {
        opacity: 1;
        background-color: rgba(#fff, 0.1);
    }
}

.table-of-contents-text-container {
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Table of Contents
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Pointer
//****************************************************************************/
.cursor-pointer {
    cursor: pointer;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Pointer
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Margin
//****************************************************************************/
.margin {
    margin: 15px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-right-40 {
    margin-right: 40px;
}

.margin-right-50 {
    margin-right: 50px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-left-25 {
    margin-left: 25px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-left-40 {
    margin-left: 40px;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Margin
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Padding
//****************************************************************************/
.padding-10 {
    padding: 10px;
}

.padding-15 {
    padding: 15px;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Padding
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Positioning
//****************************************************************************/
.position-relative {
    position: relative;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Positioning
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Pulse Blue
//****************************************************************************/
@keyframes color-pulse-blue {
    0% {
        color: rgba(var(--primary-color-rgb), 0.3);
    }
    95%,
    100% {
        color: var(--primary-color);
    }
}

@keyframes disc-pulse {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 0;
        transform: scale(1.6);
    }
    100% {
        opacity: 0;
    }
}

@keyframes rectangle-pulse {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 0;
        transform: scale(1.25);
    }
    100% {
        opacity: 0;
    }
}

.color-pulse-blue {
    animation: color-pulse-blue 0.8s alternate infinite;

    &.toolbar-icon {
        // Make sure a toolbar icon is at full opacity so that the animation is visible.
        opacity: 1;
        position: relative;
        z-index: 0; // Ensure the background is behind only the icon, not behind the rest of the screen.
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            transform: translate3d(-50%, -50%, 0);
            border-radius: 50%;
            background-color: #f4f6fa;
            z-index: -1;
        }
    }
}

.disc-pulse-blue {
    &.toolbar-icon {
        color: var(--primary-color);
        // Make sure a toolbar icon is at full opacity so that the animation is visible.
        opacity: 1;
        position: relative;
        z-index: 0; // Ensure the background is behind only the icon, not behind the rest of the screen.
        $circlePadding: 10px;

        &::before {
            content: '';
            position: absolute;
            top: calc(0px - #{$circlePadding} / 2);
            left: calc(0px - #{$circlePadding} / 2);
            width: calc(100% + #{$circlePadding});
            height: calc(100% + #{$circlePadding});
            border-radius: 50%;
            background-color: #f4f6fa;
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            top: calc(0px - #{$circlePadding} / 2);
            left: calc(0px - #{$circlePadding} / 2);
            width: calc(100% + #{$circlePadding});
            height: calc(100% + #{$circlePadding});
            border-radius: 50%;
            background-color: var(--primary-color);
            z-index: -2;
            animation: disc-pulse 2s infinite;
        }
    }
}

.rectangle-pulse-blue {
    // Make sure a UI control is at full opacity so that the animation is visible.
    opacity: 1;
    position: relative;
    z-index: 0; // Ensure the background is behind only the icon, not behind the rest of the screen.
    $circlePadding: 10px;

    &::after {
        content: '';
        position: absolute;
        top: calc(0px - #{$circlePadding} / 2);
        left: calc(0px - #{$circlePadding} / 2);
        width: calc(100% + #{$circlePadding});
        height: calc(100% + #{$circlePadding});
        background-color: var(--primary-color);
        border-radius: 3px;
        z-index: -2;
        animation: rectangle-pulse 1s infinite;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Pulse Blue
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Tasks
//****************************************************************************/
// mat-icon increases specificity.
mat-icon.tasks-icon {
    transition: all 0.2s ease;

    &.has-overdue-tasks {
        color: var(--error-color);
    }

    &.all-tasks-complete {
        color: var(--success-color);
        &:not(:hover) {
            opacity: 0.4;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Tasks
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Internal Notes
//****************************************************************************/
// mat-icon increases specificity.
mat-icon.internal-notes-icon {
    transition: all 0.2s ease;

    &.has-important-note {
        color: red;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Internal Notes
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Rounded Record List
//****************************************************************************/
/**
 * As used in
 * - custom fields editor
 * - file name pattern editor
 */
.rounded-record-list {
    background-color: white;
    border-radius: 5px;
    overflow: auto;
    box-shadow:
        0 1px 3px rgba(#000, 0.12),
        0 6px 8px rgba(#000, 0.07);
}

.rounded-record-list-item {
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 15px;
    cursor: pointer;
    transition: background 0.2s ease;

    &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
    }

    &:hover {
        background-color: var(--light-element-background-color);
    }

    &.selected {
        background-color: var(--selection-color);
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Rounded Record List
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Chrome / Angular Bugfixes
//****************************************************************************/
// Starting from Chrome v112.0, the Angular CSS caused the time input to take up two rows. This code provides a temporary fix and may be removed later (Mark 2023-04-24)
.mat-input-element[type='time']::after {
    white-space: unset !important;
}

// matTooltip prevents scrolling on input elements on mobile browsers (tested with iOS Safari).
// The issue is fixed in Material for Angular v11, since we use the legacy components, we need to fix it ourselves.
// See: https://github.com/angular/components/issues/4892
@media (hover: none) {
    .mat-tooltip-trigger {
        // Allow scrolling on input elements with tooltip on mobile browsers (tested with iOS Safari).
        -webkit-user-drag: auto !important;
        touch-action: auto !important;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Chrome / Angular Bugfixes
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Rounded Spacers
//****************************************************************************/
.rounded-spacer {
    width: 50px;
    border-radius: 99px;
    height: 5px;
    background-color: var(--primary-color);
    margin: 20px auto;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Rounded Spacers
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Short Payment Icon
//****************************************************************************/
// The mat-icon prefix must increase specificity to overwrite the default rotation
// by 0.03deg which is used for anti-aliasing the icons.
mat-icon.short-payment-icon-rotated {
    // The content_cut icon is horizontal. We need it vertical, though.
    transform: rotate(-90deg);
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Short Payment Icon
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Just-saved Overlay
//****************************************************************************/
/**
 * When saving something, you may display an overlay over the entire screen section.
 */
.just-saved-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
}

// Increased specificity necessary to overwrite element styles.
mat-icon.just-saved-success-message-icon {
    color: var(--primary-color);
    margin-bottom: 20px;
    $iconSize: 48px;
    width: $iconSize;
    height: $iconSize;
    font-size: $iconSize;
}

.just-saved-success-message {
    font-weight: 600;
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Just-saved Overlay
/////////////////////////////////////////////////////////////////////////////*/

.text-warning {
    color: var(--warning-color);
}

//*****************************************************************************
//  Slim Horizontal Progress Bar
//****************************************************************************/
.slim-progress-bar-row {
    display: flex;
    align-items: center;
}

.slim-progress-bar-background {
    flex-grow: 1;
    border-radius: 99px;
    background-color: #f1f1f1;
    overflow: hidden;
}

.slim-progress-bar-fill {
    background: linear-gradient(to right, var(--primary-color), var(--primary-color-lighter));
    border-radius: 99px;
    height: 5px;

    &.warning {
        background: linear-gradient(to right, var(--warning-color), lighten(#ffae00, 0.2));
    }

    &.alert {
        background: linear-gradient(to right, #da0000, lighten(#da0000, 0.2));
    }
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Slim Horizontal Progress Bar
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Info Panel Rows
//****************************************************************************/
/**
 * Styles the rows of dark info panels, e.g. the details panel in the report or invoice lists.
 *
 * Panel may contain info on record creator, due dates or involved parties.
 */
.info-panel-row {
    display: flex;
    align-items: center;
    min-height: 38px;
    &.has-hover {
        $horizontalPadding: 10px;
        margin-left: -#{$horizontalPadding};
        margin-right: -#{$horizontalPadding};
        padding: 10px $horizontalPadding;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.2s ease;
        &:hover {
            background: var(--button-dark-mode);
        }
    }
}

.info-panel-row_icon-container {
    $iconSize: 21px;
    width: $iconSize; // Important for other child elements relying on a unified size.
    margin-right: 15px;
    flex-shrink: 0;
    & > mat-icon {
        display: block;
        color: var(--font-light-grey);
        width: $iconSize;
        height: $iconSize;
        font-size: $iconSize;
    }
    & > img {
        display: block;
        width: $iconSize;
    }
}

.info-panel-row_text-container {
    flex-grow: 1;
    min-width: 0; // Allow shrinking past its content width, thereby allowing ellipses.
}

.info-panel-row_value {
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.info-panel-row_no-value {
    font-style: italic;
    font-weight: 300;
}

.tagging-label {
    color: var(--textColor);
    background-color: var(--backgroundColor);
    padding: 3px 6px;
    font-weight: 600;
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Info Panel Rows
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Small Overlays
//  such as the notes and tasks overlay in the Report Details progress bar.
//****************************************************************************/
.small-overlay-content {
    padding: 20px 30px;
}

.small-overlay-headline-container {
    display: flex;
    gap: 8px;
    color: var(--font-dark-grey);
    opacity: 0.5;
    margin-bottom: 20px;
}

mat-icon.small-overlay-headline-icon {
    $size: 14px;
    width: $size;
    height: $size;
    font-size: $size;
    flex-shrink: 0;
    color: inherit;
}

h2.small-overlay-heading {
    text-align: left;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1;
    color: inherit;
    letter-spacing: 0.5px;
}

.small-overlay-main-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 8px;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--font-medium-grey);
    background-color: var(--light-element-background-color);
    cursor: pointer;
    transition: background 0.2s ease;
    &:hover {
        background-color: var(--light-element-hover-color);
    }
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Overlays
/////////////////////////////////////////////////////////////////////////////*/

.background-url-ornament-bottom-full-width {
    background-image: url(/assets/images/background-ornament-blue-curve-bottom-full-width.svg);
}
.qapterixpert .background-url-ornament-bottom-full-width {
    background-image: url(/assets/images/background-ornament-orange-curve-bottom-full-width.svg);
}
